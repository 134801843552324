import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import ProductBar from 'components/ProductBar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

const NewProfuctForm = () => {
  const [isEdit, setIsEdit] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ align: [] }],
      ['link', 'image', 'video']
      // ['clean'] // remove formatting button
    ],
    clipboard: {
      matchVisual: false
    }
  };

  // console.log(textEditorValue);
  const inputRef = useRef(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      productName: '',
      productWebUrl: '',
      pricing: {
        basePrice: '',
        months: '',
        currency: '',
        discounts: [
          { discountName: '', discountDetails: '', month: 0, currency: '' }
        ]
      },
      revenue: 0,
      customerCount: '',
      capabilities: [{ label: '', property: '' }],
      targetMarket: [{ segment: '', buyersPersonas: '' }],
      customerAcquisitionCost: 0,
      monthstoRecoverCAC: '',
      customerLifetimeValue: '',
      customerEngagement: '',
      netPromoterScore: '',
      churnRate: '',
      revenueTarget: '',
      customerAcquisitionCostTarget: '',
      churnTarget: '',
      netPromoterScoreTarget: '',
      competitors: '',
      partners: ''
    }
  });

  const {
    fields: capabilitiesFields,
    append: appendCapability,
    remove: removeCapability
  } = useFieldArray({
    control,
    name: 'capabilities'
  });

  const {
    fields: targetMarketFields,
    append: appendTargetMarket,
    remove: removeTargetMarket
  } = useFieldArray({
    control,
    name: 'targetMarket'
  });

  const {
    fields: discountFields,
    append: appendDiscount,
    remove: removeDiscount
  } = useFieldArray({
    control,
    name: 'pricing.discounts'
  });

  const handleFileChange = e => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setValue('images', files); // Set the files in the form data
    }
  };

  const onSubmit = data => {
    console.log({ data });
  };

  console.log('abcd ', watch());

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-2">
        <div className="container-fluid ">
          <ProductBar
            barTitle="Add a Product"
            AddComponent={
              <Button variant="primary" type="submit">
                Add Component
              </Button>
            }
            EditLayout={
              <Button onClick={() => setIsEdit(!isEdit)}>
                {isEdit ? 'Lock Layout' : 'Edit Layout'}
              </Button>
            }
          />
          <Row className="g-2 mt-2">
            <Col xs={12} md={8}>
              <div className=" rounded-1 d-flex flex-column gap-3">
                <div className="basic-information pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Basic Information
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-name">
                      <label htmlFor="productName" className="form-label">
                        Product Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productName"
                        aria-describedby="emailHelp"
                        {...register('productName', {
                          required: 'Product name is required'
                        })}
                      />
                      {errors.productName && (
                        <p className="text-danger">
                          {errors.productName.message}
                        </p>
                      )}
                    </div>
                    <div className="pdt-web-url">
                      <label htmlFor="productWebUrl" className="form-label">
                        Product Web Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productWebUrl"
                        aria-describedby="emailHelp"
                        {...register('productWebUrl', {
                          required: 'Product Web URL is required',
                          pattern: {
                            value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/,
                            message: 'Please enter a valid URL'
                          }
                        })}
                      />
                      {errors.productWebUrl && (
                        <p className="text-danger">
                          {errors.productWebUrl.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="basic-information pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Details
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-web-url">
                      <label
                        htmlFor="productDescription"
                        className="form-label">
                        Product Description
                      </label>
                      {/* <input
                      type="text"
                      className="form-control"
                      id="productDescription"
                      aria-describedby="emailHelp"
                    /> */}
                      <ReactQuill
                        theme="snow"
                        value={watch('textEditorValue')}
                        onChange={value => setValue('textEditorValue', value)}
                        className="text-editor"
                        modules={modules}
                      />
                      {/* <MyReactQuill /> */}
                    </div>
                  </div>
                </div>

                {/* Capabilities Section */}
                <div className="pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Capabilities
                  </h6>
                  <div className="px-3 d-flex flex-column gap-3">
                    {capabilitiesFields.map((field, index) => (
                      <Row key={field.id} className="mb-3">
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Label"
                            {...register(`capabilities.${index}.label`)}
                          />
                        </Col>
                        <Col md={7}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Property"
                            {...register(`capabilities.${index}.property`)}
                          />
                        </Col>
                        <Col md={1} className="d-flex justify-content-center">
                          <FontAwesomeIcon
                            icon={faTrash}
                            color="#E63757"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeCapability(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      onClick={() =>
                        appendCapability({ label: '', property: '' })
                      }
                      variant="light"
                      className="mb-3">
                      Add Capability
                    </Button>
                  </div>
                </div>

                <div className="basic-information pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Pricing
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-web-url">
                      <Row xs={12} style={{ padding: '0 10px' }}>
                        <Col md={8} style={{ padding: '0 5px' }}>
                          <label htmlFor="basePrice">Base Price</label>
                          <input
                            type="text"
                            className="form-control"
                            id="basePrice"
                            aria-describedby="emailHelp"
                            {...register('pricing.basePrice', {
                              required: 'Base price is required',
                              valueAsNumber: true,
                              min: {
                                value: 0,
                                message: 'Base price must be a positive number'
                              }
                            })}
                          />
                          {errors.pricing?.basePrice && (
                            <p className="text-danger">
                              {errors.pricing.basePrice.message}
                            </p>
                          )}
                        </Col>
                        <Col md={2} style={{ padding: '0 5px' }}>
                          <label>Per:</label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register('pricing.months', {
                              required: 'Months is required'
                            })}>
                            <option value="Month">Month</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                          {errors.pricing?.months && (
                            <p className="text-danger">
                              {errors.pricing.months.message}
                            </p>
                          )}
                        </Col>
                        <Col md={2} style={{ padding: '0 5px' }}>
                          <label>Currency:</label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register('pricing.currency', {
                              required: 'Currency is required'
                            })}>
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                            <option value="GBP">GBP</option>
                          </Form.Select>
                          {errors.pricing?.currency && (
                            <p className="text-danger">
                              {errors.pricing.currency.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div className="pdt-web-url">
                      <Row xs={12} style={{ padding: '0 10px' }}>
                        <Col md={12} style={{ padding: '0 5px' }}>
                          <label>Discount Packages</label>
                        </Col>
                      </Row>
                    </div>

                    {/* Discount Packages */}
                    <div className="pdt-web-url">
                      {discountFields.map((field, index) => (
                        <Row
                          key={field.id}
                          xs={12}
                          style={{ padding: '0 10px', marginBottom: '10px' }}>
                          <Col md={3} style={{ padding: '0 5px' }}>
                            <label
                              htmlFor={`pricing.discounts.${index}.discountName`}
                              style={{ visibility: 'hidden' }}>
                              Discount Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Discount Name"
                              {...register(
                                `pricing.discounts.${index}.discountName`,
                                { required: 'Discount name is required' }
                              )}
                            />
                          </Col>
                          <Col md={3} style={{ padding: '0 5px' }}>
                            <label
                              htmlFor={`pricing.discounts.${index}.discountDetails`}
                              style={{ visibility: 'hidden' }}>
                              Discount Details
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Discount Details"
                              {...register(`pricing.discounts.${index}.month`, {
                                required: 'Discount month is required',
                                valueAsNumber: true,
                                min: {
                                  value: 0,
                                  message:
                                    'Discount amount must be a positive number'
                                }
                              })}
                            />
                          </Col>
                          <Col md={2} style={{ padding: '0 5px' }}>
                            <label>Per:</label>
                            <Form.Select
                              {...register(`pricing.discounts.${index}.month`)}
                              aria-label="Default select example">
                              <option value="">Select</option>
                              <option value="One">One</option>
                              <option value="Two">Two</option>
                              <option value="Three">Three</option>
                            </Form.Select>
                          </Col>
                          <Col md={2} style={{ padding: '0 5px' }}>
                            <label>Currency:</label>
                            <Form.Select
                              {...register(
                                `pricing.discounts.${index}.currency`
                              )}
                              aria-label="Default select example">
                              <option value="">Select</option>
                              <option value="USD">USD</option>
                              <option value="EUR">EUR</option>
                              <option value="GBP">GBP</option>
                            </Form.Select>
                          </Col>
                          <Col
                            md={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'end'
                            }}>
                            {discountFields.length > 1 &&
                              index !== discountFields.length - 1 && (
                                <FontAwesomeIcon
                                  onClick={() => removeDiscount(index)}
                                  icon={faTrash}
                                  color="#E63757"
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: 10
                                  }}
                                />
                              )}
                            {index === discountFields.length - 1 && (
                              <Button
                                onClick={() =>
                                  appendDiscount({
                                    discountName: '',
                                    discountDetails: '',
                                    month: '',
                                    currency: ''
                                  })
                                }
                                variant="light"
                                style={{
                                  fontSize: '14px',
                                  boxShadow: '0px 1px 2px 1px #ccc'
                                }}>
                                Add
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="basic-information pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Add Images
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-web-url">
                      <Row xs={12} style={{ padding: '0 10px' }}>
                        <Col
                          xs={12}
                          style={{
                            border: '2px dashed #D8E2EF',
                            borderRadius: '8px',
                            minHeight: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <div
                            className="images"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }}>
                            <div>
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                color="gray"
                                style={{ cursor: 'pointer' }}
                              />
                              <span style={{ marginLeft: '4px' }}>
                                Drag Your files here
                              </span>
                            </div>
                            <div>
                              or,
                              <input
                                multiple
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange} // Custom handler for file changes
                                style={{ display: 'none' }} // Hide the file input
                              />
                              <span
                                onClick={handleClick}
                                style={{ color: 'blue', cursor: 'pointer' }}>
                                Browse
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <p className="px-3 pt-2">
                    {watch('images')?.length} file
                    {watch('images')?.length > 1 && 's'} selected
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className=" rounded-1">
                <div className="basic-information pb-4 bg-white">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Performance Metrics
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-name">
                      <label htmlFor="customerCount" className="form-label">
                        Customer Count
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerCount"
                        aria-describedby="emailHelp"
                        {...register('customerCount')}
                      />
                    </div>
                    <div className="pdt-web-url">
                      <label htmlFor="recurringRevenue" className="form-label">
                        Recurring Revenue
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recurringRevenue"
                        aria-describedby="emailHelp"
                        {...register('revenue', {
                          required: 'Revenue is required',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message: 'Revenue must be a positive number'
                          }
                        })}
                      />
                      {errors.revenue && (
                        <p className="text-danger">{errors.revenue.message}</p>
                      )}
                    </div>
                    <div className="pdt-web-url">
                      <label
                        htmlFor="customerAcquisitionCost"
                        className="form-label">
                        Customer Acquisition Cost
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerAcquisitionCost"
                        aria-describedby=""
                        {...register('customerAcquisitionCost')}
                      />
                    </div>
                    <div className="pdt-web-url">
                      <label
                        htmlFor="monthsToRecoverCac"
                        className="form-label">
                        Months to Recover CAC
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="monthsToRecoverCac"
                        aria-describedby="emailHelp"
                        {...register('monthstoRecoverCAC')}
                      />
                    </div>
                    <div className="pdt-web-url">
                      <label
                        htmlFor="customerLifetimeValue"
                        className="form-label">
                        Customer Lifetime Value
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerLifetimeValue"
                        aria-describedby="emailHelp"
                        {...register('customerLifetimeValue')}
                      />
                    </div>
                    <div className="pdt-web-url">
                      <label
                        htmlFor="customerEngagement"
                        className="form-label">
                        Customer Engagement
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerEngagement"
                        aria-describedby="emailHelp"
                        {...register('customerEngagement')}
                      />
                    </div>
                    <div className="pdt-web-url">
                      <label htmlFor="netPromoterScore" className="form-label">
                        Net Promoter Score
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="netPromoterScore"
                        aria-describedby="emailHelp"
                        {...register('netPromoterScoreTarget', {
                          required: 'Net promoter score target is required',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'Net promoter score target must be a positive number'
                          }
                        })}
                      />
                      {errors.netPromoterScoreTarget && (
                        <p className="text-danger">
                          {errors.netPromoterScoreTarget.message}
                        </p>
                      )}
                    </div>
                    <div className="pdt-web-url">
                      <label htmlFor="churnRate" className="form-label">
                        Churn Rate
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="churnRate"
                        aria-describedby="emailHelp"
                        {...register('churnRate')}
                      />
                    </div>
                  </div>
                </div>
                <div className="basic-information pb-4 mt-2 bg-white">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Growth Targets
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <div className="pdt-name">
                      <label htmlFor="revenueTarget" className="form-label">
                        Revenue Target
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="revenueTarget"
                        aria-describedby="emailHelp"
                        {...register('revenueTarget')}
                      />
                    </div>
                    <div className="pdt-name">
                      <label
                        htmlFor="customerAcquisitionCostTarget"
                        className="form-label">
                        Customer Acquisition Cost Target
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customerAcquisitionCostTarget"
                        aria-describedby="emailHelp"
                        {...register('customerAcquisitionCost', {
                          required: 'Customer acquisition cost is required',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'Customer acquisition cost must be a positive number'
                          }
                        })}
                      />
                    </div>
                    <div className="pdt-name">
                      <label htmlFor="churnTarget" className="form-label">
                        Churn Target
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="churnTarget"
                        aria-describedby="emailHelp"
                        {...register('churnTarget')}
                      />
                    </div>
                    <div className="pdt-name">
                      <label
                        htmlFor="netPromoterSoreTarget"
                        className="form-label">
                        Net Promoter Sore Target
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="netPromoterSoreTarget"
                        aria-describedby="emailHelp"
                        {...register('netPromoterScoreTarget')}
                      />
                    </div>
                  </div>
                </div>

                {/* Target Market Section */}
                <div className="pb-4 bg-white rounded-1">
                  <h6
                    className="py-3 px-3"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Target Market
                  </h6>
                  <div className="px-3 d-flex flex-column gap-3">
                    {targetMarketFields.map((field, index) => (
                      <Row key={field.id} className="mb-3">
                        <Col md={6}>
                          <Form.Select
                            {...register(`targetMarket.${index}.segment`)}>
                            <option>Choose Segment</option>
                            <option value="Segment 1">Segment 1</option>
                            <option value="Segment 2">Segment 2</option>
                          </Form.Select>
                        </Col>
                        <Col md={6}>
                          <Form.Select
                            {...register(
                              `targetMarket.${index}.buyersPersonas`
                            )}>
                            <option>Choose Buyer Persona</option>
                            <option value="Persona 1">Persona 1</option>
                            <option value="Persona 2">Persona 2</option>
                          </Form.Select>
                        </Col>
                        <Col xs={12} className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            onClick={() => removeTargetMarket(index)}
                            icon={faTrash}
                            color="#E63757"
                            style={{ cursor: 'pointer', marginTop: 10 }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      onClick={() =>
                        appendTargetMarket({ segment: '', buyersPersonas: '' })
                      }
                      variant="light">
                      Add Target Market
                    </Button>
                  </div>
                </div>

                <div className="basic-information pb-4 mt-2 bg-white">
                  <h6
                    className="py-3 px-3 rounded-1"
                    style={{ backgroundColor: '#F9FAFD' }}>
                    Top Competitors and Partners
                  </h6>
                  <div className="px-3 basic-info-form d-flex flex-column gap-3">
                    <Row>
                      <Col xs={12} style={{ padding: '0 10px' }}>
                        <div className="pdt-name">
                          <label htmlFor="revenueTarget" className="form-label">
                            Competitors
                          </label>
                          <Form.Select
                            {...register('competitors')}
                            aria-label="Default select example">
                            <option>
                              Multi-select Drop Down list from Competitor screen
                            </option>
                            <option>Test</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        style={{ padding: '0 10px', marginTop: '20px' }}>
                        <div className="pdt-name">
                          <label htmlFor="revenueTarget" className="form-label">
                            Partners / Resellers
                          </label>
                          <Form.Select
                            {...register('partners')}
                            aria-label="Default select example">
                            <option>Laptop</option>
                            <option>Test</option>
                          </Form.Select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '20px' }}>
          <ProductBar barTitle="You are Almost done" />
        </div>
      </div>
    </form>
  );
};

export default NewProfuctForm;
