import { colorsObj, colorsObjDark, sidebarConfig } from 'helpers/utils';
import React from 'react';
import { ListGroup, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
const AmanitaSideNav = () => {
  const isDark = false;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      style={{
        backgroundColor: isDark ? colorsObjDark.sidebg : 'white',
        overflowY: 'auto',
        height: '89vh'
      }}
      className="pe-2 pb-2 d-none d-lg-block">
      <Stack className="">
        <h1
          style={{
            fontSize: '1rem',
            textAlign: 'center',
            padding: '0.8rem 0'
          }}>
          Company Name
        </h1>

        {sidebarConfig.map((v, subIndex) => (
          <ListGroup
            defaultActiveKey="#link1"
            key={subIndex}
            className="pb-3"
            style={{ paddingLeft: 10 }}>
            <p
              className="m-0  mb-2"
              style={{
                color: isDark ? 'white' : 'black'
              }}>
              {v.subheader}
            </p>
            {v.items.map((item, itemIndex) => {
              return (
                <ListGroup.Item
                  onClick={() => {
                    navigate(item.path);
                  }}
                  action
                  key={itemIndex}
                  className="m-0 py-2 mt-2 px-3 cursor-pointer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    backgroundColor:
                      pathname === item.path
                        ? isDark
                          ? '#b85944'
                          : '#F6EFDB'
                        : isDark
                        ? '#762615'
                        : colorsObj.halfWhite,
                    borderRadius: 10,
                    border: 0,
                    color: isDark ? 'white' : 'black'
                  }}>
                  {item.icon(isDark)}
                  {item.title}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ))}
      </Stack>
    </div>
  );
};

export default AmanitaSideNav;
