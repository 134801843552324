import React from 'react';
import SurveyCreator from '../../components/SurveyCreator';
import { json } from '../../data/survey_json';

export function CreatorPage() {
  return (
    <>
      <h1>Survey Creator / Form Builder</h1>
      <SurveyCreator json={json} />
    </>
  );
}
